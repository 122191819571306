import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/helpers/common.service';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { TechstrategyService } from 'src/app/providers/input/techstrategy.service';
import { LoadingService } from 'src/app/providers/loading/loading.service';
// Import the Quill library
import Quill from 'quill';
// Import the Quill Image Resize Module for p-editor
import ImageResize from 'quill-image-resize-module';

// Register the Image Resize Module
Quill.register('modules/imageResize', ImageResize);
@Component({
    selector: 'app-visiongoals',
    templateUrl: './itvisiongoals.component.html',
    styleUrls: ['./itvisiongoals.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ITVisiongoalsComponent implements OnInit {
    editorModules = {
        imageResize: {},
    };
    isAdd: any = false;
    goalObj: any = {
        clr: '',
        goal: '',
        icon: '',
        desc: '',
        spi: '',
        owner: '',
        _id: ''
    }
    ownerData: any[] = [];
    headerText: string;
    constructor(private config: DynamicDialogConfig,
        public ref: DynamicDialogRef, private techstrategyService: TechstrategyService,
        private loadingService: LoadingService,
        private dashboardService: DashboardService,
        private commonService: CommonService,
        private sanitizer: DomSanitizer) { }

    ngOnInit(): void {
        this.getgoal()
    }

    getgoal() {
        if (this.config?.data?.isEdit) {
            this.ownerData = this.config?.data?.goalOwners;
            this.goalObj.clr = this.config?.data?.goal?.clr;
            this.goalObj.goal = this.config?.data?.goal?.goal;
            this.goalObj.desc = this.config?.data?.goal?.desc;
            this.goalObj.spi = this.config?.data?.goal?.spi;
            this.goalObj.owner = this.config?.data?.goal?.owner;
            this.goalObj._id = this.config?.data?.goal?._id;
            this.goalObj.icon = this.config?.data?.goal?.icon;
            this.ownerData = this.config?.data?.ownerData;
            this.headerText = "Edit Goal";
        }
        else {
            if (this.config?.data?.goal) {
                this.goalObj = JSON.parse(JSON.stringify(this.config?.data?.goal));
            }
            this.ownerData = this.config?.data?.ownerData;
            this.headerText = "New Goal";
        }

    }
    saveGoal() {
        let req = this.config?.data?.visiondata;
        let index = req.goals.findIndex((x: any) => x._id == this.goalObj._id);
        if (index != -1) {
            req.goals[index] = this.goalObj;
            this.techstrategyService.SaveTechVision(req).subscribe(res => {
                this.ref.close({ goalObj: this.goalObj, isSaved: true });
            });
        }
        else {
            this.goalObj._id = undefined;
            req?.goals?.push(this.goalObj);
            this.techstrategyService.SaveTechVision(req).subscribe(res => {
                this.ref.close({ goalObj: this.goalObj, isSaved: true });
            });
        }
        this.getgoal();
    }

    onClose() {
        this.ref.close({ goalObj: this.goalObj, isSaved: false });
    }

    getpic(id: any) {
        const ownerObj = this.ownerData.find(x => x._id == id);
        return ownerObj && ownerObj.pic ? ownerObj.pic : '../../../../../../assets/images/public/default-avatar.png';
    }

    openImagePopup() {
        this.ref.close({ goalObj: this.goalObj, isSaved: false });
    }

    getSelectedImage(url: string) {
        return this.dashboardService.getSafeUrl(url);
    }
}
