import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoadingService } from 'src/app/providers/loading/loading.service';
import { GovernDeliverService } from 'src/app/providers/input/govern-deliver.service';
import { AppService } from 'src/app/providers/appservice/app.service';
import { BusinessStrategyService } from 'src/app/providers/input/business-strategy.service';
import { ManagedataService } from 'src/app/providers/input/managedata/managedata.service';
import { CommonService } from 'src/app/helpers/common.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { Appconfig } from 'src/app/configurations/appconfig';

@Component({
  selector: 'app-portfolio-theme-popup',
  templateUrl: './portfolio-theme-popup.component.html',
  styleUrls: ['./portfolio-theme-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PortfolioThemePopupComponent implements OnInit {
  projectData: any;
  BusinessObjectives: any;
  Objective: any;
  projectList: any[] = [];
  isProjectData = true;
  ownerData: any[] = [];
  company: any;
  selectedObjective: any;
  screen: string;

  constructor(
    private appServices: AppService,
    private managedata: ManagedataService,
    private governService: GovernDeliverService,
    private BusinessService: BusinessStrategyService,
    private Loadingservice: LoadingService,
    private appservice: AppService,
    private commonService: CommonService,
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private dashboardService: DashboardService
  ) { }

  ngOnInit(): void {
    // this.getprojectdata();
    this.getOwnerData()
    this.commonService.getCompany(this.appservice.currentActivatedFeature.strategyId).subscribe(x => {
      this.company = x;
      this.projectData = this.config?.data?.projectData;
      this.selectedObjective = this.config?.data?.selectedObjective;
      this.screen = this.config?.data?.screen;
      this.totalInvestment();
      this.dashboardService.getSelectedImage(this.projectData.pic)
        .subscribe(signedUrl => this.projectData.pic = signedUrl)
      this.projectData?.objectives?.forEach((obj: any) => {
        this.dashboardService.getSelectedImage(obj.pic)
          .subscribe(signedUrl => obj.pic = signedUrl)
      })
      this.BusinessObjectives = this.config?.data?.businessObjectives;
    });
  }

  totalInvestment() {
    this.projectData.tcapex = 0;
    this.projectData.topex = 0;
    this.projectData.investment.forEach((obj: any) => {
      if (!this.projectData.tcapex) {
        this.projectData.tcapex = 0;
      }
      if (!this.projectData.topex) {
        this.projectData.topex = 0;
      }
      this.projectData.tcapex += obj.capex_allocated;
      this.projectData.topex += obj.opex_eft_allocated;
    });
  }

  getOwnerData() {
    this.managedata
      .getHRDataForProject(this.appservice.currentActivatedFeature.strategyId)
      .subscribe(res => {
        this.ownerData = res;
      });
  }

  getSponsor(id: any) {
    const sponsor = this.ownerData.find(x => x._id === id);
    return sponsor ? { empName: sponsor.empName, pic: sponsor.pic } : { empName: '', pic: '' };
  }

  backToObjective() {
    this.ref.close({});
  }

  getFATAColor(val: any) {
    let colorObj: any;
    if (val > 4) {
      colorObj = Appconfig.CriticalColorIndicators['green'];
    }
    else if (val > 2) {
      colorObj = Appconfig.CriticalColorIndicators['amber'];
    }
    else if (val > 0) {
      colorObj = Appconfig.CriticalColorIndicators['red'];
    }
    else
      colorObj = Appconfig.CriticalColorIndicators['grey'];
    return colorObj;
  }

}
