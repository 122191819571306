import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Appconfig } from 'src/app/configurations/appconfig';
import { INeedForChange } from 'src/app/models/input/itdiagnostics/needforchange';
import { IAuthor } from 'src/app/models/input/itdiagnostics/author';
import { CoreService } from '../core.service';
import { ISection } from 'src/app/models/input/itdiagnostics/section';
import { Heatmap } from 'src/app/models/input/itdiagnostics/heatmap';
import { IStrength } from 'src/app/models/input/itdiagnostics/itswotmatrix';
import { ActivatedRoute } from '@angular/router';
import { AppService } from '../appservice/app.service';

@Injectable({
  providedIn: 'root'
})
export class itDiagnosticsService extends CoreService {


  execSummaryPath: string;
  needForChangePath: string;
  heatMapPath: string;
  defaultHeatMapPath: string;
  itSwotAnalysisPath: string;
  itMaturityAssessment: string;
  itPostMaturityAssessment: string;
  isSupplierHeatMap: string;
  criticalHeatmap: string;
  portfolioAnalysis: string;
  createPortfolio: string;
  postPortfolioAnalysisPath: string;
  constructor(httpClient: HttpClient, private appService: AppService) {
    super(httpClient);
    this.setInitialValues();
  }

  setInitialValues() {

    this.execSummaryPath = '/modules/ciointro';
    this.needForChangePath = '/modules/needforchange';
    this.heatMapPath = '/';
    this.defaultHeatMapPath = '/default';
    this.itSwotAnalysisPath = '/modules/';
    this.itMaturityAssessment = '/modules/assessment';
    this.itPostMaturityAssessment = '/csa/assessment/';
    this.isSupplierHeatMap = '/masterdata/supplierdata';
    this.criticalHeatmap = '/masterdata/applicationdata';
    this.portfolioAnalysis = '/modules/portfolioanalysis';
    this.createPortfolio = '/createportfolio/';
    this.postPortfolioAnalysisPath = '/csa/portfolioanalysis/';
  }



  //
  // Executive Summary Start

  getAuthor() {
    // this.setInitialValues();
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.execSummaryPath).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  postAuthor(author: IAuthor) {
    const payLoad: any = {
      author: author.userName,
      designation: author.designation,
      quote: author.quote,
      pic: author.pic
    };
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.execSummaryPath, payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  postCategory(section: ISection) {
    const payLoad: any = {
      desc: section.bodyText
    };
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.execSummaryPath, payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  postSection(section: ISection) {
    const payLoad: any = {
      title: section.headerTitle, desc: section.bodyText, sort_order: section.sort_order
    };
    if (section.id) {
      return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/ciointro/section/update/' + section.id, payLoad).pipe(map(
        respData => {
          console.log(respData);
          return respData.body;
        }
      ));
    }
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/ciointro/section/add', payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  reOrderExecSummary(payload: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/v3/' + this.appService.strategyId + '/exec-summary/categories/reorder', payload).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }


  // Executive Summary End


  // Need For Change Start

  getNeedForChange() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.needForChangePath).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  postNeedForChange(needForChange: INeedForChange) {
    const payLoad: any = needForChange;
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.needForChangePath, payLoad).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  // Need For Change End


  // Start Heat Map

  getHeatMap(type: any) {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.heatMapPath + type).pipe(map(
      respData => {
        return respData.body;
      }));
  }
  getDefaultHeatMap(type: any) {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + "/heatmap" + "/" + type + this.defaultHeatMapPath).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  getImportData() {
    return this.get(Appconfig.APIENDPOINT.HOME_MODULES.COMPANIES).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  postHeatMap(heatMap: Heatmap, type: any, id: any) {
    const payLoad: any = heatMap;
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.heatMapPath + type + '/' + id, payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }
  resetHeatMap(app_Id: any, sec_Id: any) {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + "/v3/" + this.appService.strategyId + "/reset/critical-applications-heatmap/" + sec_Id + "/" + app_Id).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  getCapabilityList(type: any) {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + "/v3/" + type + "/capabilitieslist").pipe(map(
      respData => {
        return respData.body;
      }));
  }

  // End Heat Map


  // IT Swot Analysis Start

  getSwotAnalysis(screenName: string) {
    this.setInitialValues();
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.itSwotAnalysisPath + screenName).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  postSwotMatrix(data: any, screenName: string) {
    const payLoad: any = data;

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.itSwotAnalysisPath + screenName, payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }


  // IT Swot Analysis End


  // IT Maturity Assessment Start

  getMaturtyAssessment() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.itMaturityAssessment).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  postAssessment(data: any, id: any) {
    const payLoad: any = data;

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.itPostMaturityAssessment + id, payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  ResetMaturitySection(cd: any) {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/v3' + '/' + this.appService.strategyId + '/restore/it_diagnostic_maturity_assesment-' + cd).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  restoreAppHeatmap(sectionCode: string, appID: string) {
    return this.get(`${Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS}/v3/${this.appService.strategyId}/restore/appheatmap/${sectionCode}/${appID}`).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  // IT Maturity Assessment End


  //IT Supplier Heatmap

  getSupplierHeatmap() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.isSupplierHeatMap).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  saveSupplierHeatMap(data: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/v3/' + this.appService.strategyId + '/masterdata/supplier/heatmap', data).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  // IT Supplier heatmap


  // Critical Application Heatmap start

  getData(path: string) {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + path).pipe(map(
      respData => {
        return respData.body;
      }));
  }

  getCriticalHeatmap() {
    this.setInitialValues();
    return this.getData('/' + this.appService.strategyId + this.criticalHeatmap);
  }

  getPortfolioAnalysis() {
    return this.getData('/' + this.appService.strategyId + this.portfolioAnalysis);
  }

  getCreatePortfolio(id: any) {
    return this.getData('/' + this.appService.strategyId + this.createPortfolio + id);
  }

  postCriticalHeatmap(data: any, id: any) {
    const payLoad: any = data;

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + this.postPortfolioAnalysisPath + id, payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  updateLabelAppHeatmap(data: any) {
    const payLoad: any = data;
    // /v3/:strategy_id/application-heatmap/update-label
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/v3/' + this.appService.strategyId + '/application-heatmap/update-label', payLoad).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  updateHeatmapQus(data: any) {
    const payLoad: any = data;

    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/v3/' + this.appService.strategyId + '/portfolioanalysis/update-qus', payLoad).pipe(map(
      respData => {
        return respData.body;
      }
    ));
  }

  deleteSections(section_id: any) {
    const url = Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/ciointro/section/delete/' + section_id;
    return this.delete(url).pipe(map(() => {
      return true;
    }
    ));
  }

  // Critical Application Heatmap end

  //Key Findings

  getKeyFindings(heatmapType: string, capability_id: string) {
    return this.get(`${Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS}/v3/${this.appService.strategyId}/summary-key-findings/${heatmapType}/${capability_id}`).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  saveSummaryKeyFindings(data: any, summaryId?: any) {
    const saveOrModUrl = summaryId ? '/summary-key-findings/' + summaryId : '/summary-key-findings'
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + saveOrModUrl, data).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  deleteKeyFindings(id: any) {
    return this.delete(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/summary-key-findings/' + id).pipe(map(
      respData => {
        return respData.body;
      }
    ))
  }

  //Key Findings

  //org Chart
  saveOrgChart(data: any) {
    return this.post(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/v3' + '/' + this.appService.strategyId + '/org-chart/create', data).pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }

  getOrgChart() {
    return this.get(Appconfig.APIENDPOINT.INPUT_MODULES.ITDIAGNOSTICS + '/' + this.appService.strategyId + '/modules/orgchart').pipe(map(
      respData => {
        console.log(respData);
        return respData.body;
      }
    ));
  }
  //org Chart

}
