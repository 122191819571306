<section>
    <div class="popu-heade d-flex justify-content-between mb-2 p-3 ">
        <h4 class="text-light h-fs text-uppercase mt-2">{{headerText}}</h4>

        <div class="d-flex">
            <button class="btn btn-info2 ms-2 text-light py-1 px-3 me-2 float-end" (click)="saveGoal()">
                <img src="../../../../../../assets/images/uploads/save.svg" alt="" alt="" width="20" height="20"
                    class="mb-1"> Save
            </button>
            <button type="button" class="btn btn-info2 rounded-circle text-light" data-bs-dismiss="modal"
                (click)="onClose()">
                <img src="../../../../../../assets/images/uploads/close-white.svg" height="15px">
            </button>

        </div>

    </div>
    <div class="container-fluid px-3 pb-3">
        <div class="row">
            <div class="col-lg-12">

            </div>
        </div>

    </div>
    <div class="modal-body px-0">
        <div class="container-fluid px-4 pb-2">
            <div class="row">
                <div class="col-lg-9">
                    <p class="fs-18 text-info2 fw-500 mb-1">HEADING</p>
                    <div class="d-flex align-items-end">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            class="bi bi-circle-fill me-3 mb-3 cursor" viewBox="0 0 16 16" [style.color]="goalObj.clr"
                            [cpSaveClickOutside]="true" [cpOKButtonClass]="'btn btn-primary btn-xs'"
                            [(colorPicker)]="goalObj.clr" [(ngModel)]="goalObj.clr">
                            <circle cx="8" cy="8" r="8" />
                        </svg>

                        <img [src]="goalObj.icon || '../../../../../../assets/images/uploads/DefaultIcon.png'"
                            class="border rounded-3 border-secondary p-2 mb-1 me-3 cursor" height="48px"
                            data-bs-toggle="modal" data-bs-target="#ImageUpload" data-bs-toggle="modal"
                            data-bs-target="#ImageUpload" (click)="openImagePopup()" />
                        <div class="form-control border-0">
                            <!-- <div class="editor-icons-group border-0">
                                <img src="../../../../../../assets/images/uploads/editor-icons/bold.svg" alt="" />
                                <img src="../../../../../../assets/images/uploads/editor-icons/italic.svg" alt="" />
                                <img src="../../../../../../assets/images/uploads/editor-icons/underline.svg" alt="" />
                            </div> -->
                            <!-- <div class="form-floating text-dark">
                                <input type="text" class="form-control pb-3 fw-bold our-people"
                                    id="floatingInput text-flield" placeholder="Our People" required=""
                                    value="Our People" [(ngModel)]="goalObj.goal" />
                            </div> -->
                            <p-editor [(ngModel)]="goalObj.goal" name="overview" class="px-0 editor-desc"
                                [style]="{'height':'70px'}">
                                <ng-template pTemplate="header">
                                    <span class="ql-formats">
                                        <button type="button" class="ql-bold" aria-label="Bold"></button>
                                        <button type="button" class="ql-italic" aria-label="Italic"></button>
                                        <button type="button" class="ql-underline" aria-label="Underline"></button>
                                    </span>
                                </ng-template>
                            </p-editor>
                        </div>

                    </div>

                </div>
                <div class="col-lg-3">
                    <p class="fs-18 text-info2 fw-500 mb-0 mb-lg-3 mt-5 mt-lg-0">
                        GOAL OWNER
                    </p>
                    <div class="form-control p-0 pt-lg-4 border-0 d-flex align-items-end goa-own">
                        <img [src]="getpic(goalObj.owner)"
                            class="border rounded-3 border-secondary me-3 image-container " />
                        <div class="form-floating text-dark w-100">
                            <p-dropdown class="goalit" inputId="type" [autoDisplayFirst]="false" [options]="ownerData"
                                [(ngModel)]="goalObj.owner" optionLabel="empName" optionValue="_id"
                                placeholder="Select"></p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class=" px-4 ">
            <p class="fs-18 text-info2 fw-500 mb-lg-2">DESCRIPTION</p>
            <p-editor [(ngModel)]="goalObj.desc" class="ps-0  editor-desc" [style]="{'height':'330px'}"
                [modules]="editorModules"></p-editor>
            <!-- <div class="ditor-icons mb-4 d-flex flex-wrap pb-3">
                <div class="editor-icons-group">
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/bold.svg" title="Bold" />
                    </a>
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/italic.svg" title="Itailc" />
                    </a>
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/underline.svg"
                            title="Underline" />
                    </a>
                </div>
                <div class="editor-icons-group">
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/order-list.svg" title="Bold" />
                    </a>
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/unorder-list.svg"
                            title="Itailc" />
                    </a>
                </div>
                <div class="editor-icons-group">
                    <select name="font weight">
                        <option value="">Normal</option>
                        <option value="">Normal</option>
                        <option value="">Normal</option>
                        <option value="">Normal</option>
                    </select>
                </div>
                <div class="editor-icons-group">
                    <select name="font weight">
                        <option value="">Font</option>
                        <option value="">Font</option>
                        <option value="">Font</option>
                        <option value="">Font</option>
                    </select>
                </div>
                <div class="editor-icons-group">
                    <select name="font weight">
                        <option value="">18</option>
                        <option value="">20</option>
                        <option value="">22</option>
                        <option value="">24</option>
                    </select>
                </div>
                <div class="editor-icons-group">
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/image.svg" title="Add Image" />
                    </a>
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/block.svg" />
                    </a>
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/align.svg" />
                    </a>
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/ohm.svg" />
                    </a>
                    <a href="" class="text-decoration-none">
                        <img src="../../../../../../assets/images/uploads/editor-icons/earth.svg" />
                    </a>
                </div>
            </div> -->
            <!-- <textarea class="form-control fs-15" id="exampleFormControlTextarea1" rows="1" [(ngModel)]="goalObj.desc">
             Lorem ipsum dolor sit amet, dolore magna aliquyam erat, sed diam voluptua.</textarea> -->

        </div>
        <!-- <div class="editor-dec px-4 d-none">
            <p class="fs-18 text-info2 fw-500 mb-lg-2">
                STRATEGIC PERFORMANCE INDICTOR </p>
                <p-editor [(ngModel)]="goalObj.spi" class="ps-0  editor-desc" [style]="{'height':'110px'}"></p-editor>
        </div> -->
    </div>
</section>