import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from '../helpers/common.service';
import { AppService } from '../providers/appservice/app.service';
import { DashboardService } from '../providers/home/dashboard.service';

@Component({
  selector: 'app-supplierheatmp',
  templateUrl: './supplierheatmp.component.html',
  styleUrls: ['./supplierheatmp.component.scss']
})
export class SupplierheatmpComponent implements OnInit {
 supdata:any;
 company:any;
 supplier:any=[];
 supplierPic: any;
 screen: string;
 selectedObjective: any = {};
  constructor(
    private config: DynamicDialogConfig,
    private commonService: CommonService,
    private appService: AppService,
    private dashboardService: DashboardService,
    public ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this.screen = this.config?.data?.screen;
    this.selectedObjective = this.config?.data?.selectedObjective || {}
    this.supdata = this.config?.data?.supdata;
    this.dashboardService.getSelectedImage(this.supdata.pic)
    .subscribe((url: any) => this.supplierPic = url)
    this.commonService.getCompany(this.appService.currentActivatedFeature.strategyId).subscribe(x => { this.company = x; });
  }



  getBgColor(data: any) {
    switch (data) {
      case 'Not Assessed':
        return { 'background-color': '#c3c3c3' };
      case 'Severe Concern':
        return { 'background-color': '#8d1101' };
      case 'Significant Concern':
        return { 'background-color': '#d90606' };
      case 'Moderate Concern':
        return { 'background-color': '#ffcf11' };
      case 'No Concerns':
        return { 'background-color': '#4dc013' };
      case 'Not Applicable':
        return { 'background-color': '#ededed' };
      default:
        return { 'background-color': 'gray', 'color': 'black' };
    }
  }

  getdecisionicon(data: any){
    switch (data) {
      case 'Undecided':
        return ' ../../../../../../assets/images/uploads/arrows/botom-right.svg' ;
      default:
        return ' ../../../../../../assets/images/uploads/arrows/merge.png';
    }
  }

  backToObjective(){
    this.ref.close({});
  }
}
