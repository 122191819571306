// auth.interceptor.ts

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../providers/auth/auth.service';
import { CommonService } from '../helpers/common.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService, private commonService: CommonService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.commonService.clearBrowserStorages();
          this.router.navigate(['/auth/login']);
        }
        return throwError(error);
      })
    );
  }
  logout() {
    this.authService.logout().subscribe({
      next: () => {
        this.commonService.clearBrowserStorages();
        this.router.navigate(['auth', 'login']);
      }, error: () => {
        this.commonService.clearBrowserStorages();
        this.router.navigate(['auth', 'login']);
      },
    });
  }
}
