<!-- <div class="modal fade" #ObjectiveBackdrop id="ObjectiveBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
    tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered ">
        <div class="modal-content">
            <div class="modal-header py-2 bg-info1 text-light">
                <h1 class="modal-title fs-18" id="staticBackdropLabel">OUR PEOPLE</h1>
                <div>
                    <button class="btn btn-info2 ms-2 text-light fs-18 px-3 py-1 me-2 float-end float-md-none">
                        <img src="../../../../../../assets/images/uploads/save.svg" alt=""> Save </button>
                    <button type="button" class="btn btn-info2 text-light rounded-circle" data-bs-dismiss="modal">
                        <img src="../../../../../../assets/images/uploads/close-white.svg" height="11px">
                    </button>
                </div>
            
            </div> -->
<div class="bar2">
    <section>
        <div class=" d-flex justify-content-between bar mb-2 p-3 ">
            <p class="align-center h-fs text-uppercase text-light">{{headerText}}</p>


            <div class="d-flex">
                <button class="btn btn-info2 ms-2 text-light  px-3 py-1 me-4 float-end" (click)="SaveClick()">
                    <img src="../../../../../../assets/images/uploads/save.svg" alt="" width="20" height="20"
                        class="mb-1"> Save
                </button>
                <button type="button" class="btn btn-info-light rounded-circle border-light btn2"
                    data-bs-dismiss="modal" (click)="onClose()">
                    <img src="../../../../../../assets/images/uploads/close-white.svg" height="15px">
                </button>

            </div>
        </div>

        <div class="container-fluid">

            <div class="row">
                <div class="col-lg-12">


                </div>
            </div>
            <div class="row ps-1 pe-2 ">
                <div class="col-lg-6 mb-2">
                    <p class="fs-18 text-info2 fw-500 mb-1">HEADING</p>
                    <div class="d-flex align-items-end">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            class="bi bi-circle-fill me-3 mb-3 cursor" viewBox="0 0 16 16" [cpSaveClickOutside]="true"
                            [cpOKButtonClass]="'btn btn-primary btn-xs'" [(colorPicker)]="objective.color"
                            [(ngModel)]="objective.color" [style.color]="objective.color">
                            <circle cx="8" cy="8" r="8" />
                        </svg>
                        <img [src]="objectivePic || '/assets/images/uploads/DefaultIcon.png'"
                            class="border rounded-3 border-secondary p-2 mb-1 me-3 cursor" height="48px"
                            data-bs-toggle="modal" data-bs-target="#ImageUpload" (click)="openObjectiveImagePopup()">
                        <div class="form-control border-0">
                            <!-- <div class="editor-icons-group border-0">
                                <img src="../../../../../../assets/images/uploads/editor-icons/bold.svg" alt="">
                                <img src="../../../../../../assets/images/uploads/editor-icons/italic.svg" alt="">
                                <img src="../../../../../../assets/images/uploads/editor-icons/underline.svg" alt="">
                            </div> 
                              <div class="form-floating text-dark">
                                <input type="text" class="form-control pb-3 fw-bold our-people"
                                    id="floatingInput text-flield" placeholder="Heading" required=""
                                    [(ngModel)]="objective.title">
                            </div>  -->
                            <p-editor [(ngModel)]="objective.title" class="t-fs editor-dec hj"
                                [style]="{'height':'80px'}">
                                <ng-template pTemplate="header">
                                    <span class="ql-formats">
                                        <button type="button" class="ql-bold" aria-label="Bold"></button>
                                        <button type="button" class="ql-italic" aria-label="Italic"></button>
                                        <button type="button" class="ql-underline" aria-label="Underline"></button>
                                    </span>
                                </ng-template>
                            </p-editor>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 ">
                    <p class="fs-18 text-info2 fw-500 mb-0   mt-lg-0">OBJECTIVE SPONSOR</p>
                    <div class="form-control p-0 border-0 d-flex align-items-end" style="margin-top: 65px;">
                        <img [src]="sponsorPic || '/assets/images/public/default-avatar.png'"
                            class="border rounded-3 border-secondary avatar me-3">
                        <div class="form-floating text-dark wid-adj  ">

                            <p-dropdown inputId="type" [autoDisplayFirst]="false" [options]="ownerData"
                                [(ngModel)]="objective?.sponsor._id" optionLabel="empName" optionValue="_id"
                                (onChange)="onSponsorChange()" placeholder="Select"></p-dropdown>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 pe-4">
                    <div class="d-flex align-items-center justify-content-between h-100 mt-5">
                        <p class="fs-15 fw-bold text-info2 text-info1" style="margin-left: 10px;">QUALIFY</p>
                        <label class="switch float-end ">
                            <input type="checkbox" [(ngModel)]="isshowChecked">
                            <span class="slider round"></span>
                        </label>
                    </div>

                </div>

            </div>
        </div>
        <hr class="m-0">
        <div class="container-fluid px-3 pt-3" style="height: 200px;">
            <div class="row">

                <div class="col-lg-6">
                    <div class="editor-dec">
                        <p class="hs-f text-info2 fw-500 ">DESCRIPTION</p>
                        <p-editor [(ngModel)]="objective.desc" [modules]="editorModules" class="ps-0 h-fs"
                            [style]="{'height':'100px'}"></p-editor>
                        <!-- <div class="ditor-icons  d-flex flex-wrap ">
                            <div class="editor-icons-group">
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/bold.svg"
                                        title="Bold" height="10px">
                                </a>
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/italic.svg"
                                        title="Itailc">
                                </a>
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/underline.svg"
                                        title="Underline">
                                </a>
                            </div>
                            <div class="editor-icons-group">
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/order-list.svg"
                                        title="Bold">
                                </a>
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/unorder-list.svg"
                                        title="Itailc">
                                </a>
                            </div>
                            <div class="editor-icons-group">
                                <select name="font weight" width="68px">
                                    <option value="">Normal</option>
                                    <option value="">Normal</option>
                                    <option value="">Normal</option>
                                    <option value="">Normal</option>
                                </select>
                            </div>
                            <div class="editor-icons-group">
                                <select name="font weight" width="68px">
                                    <option value="">Font</option>
                                    <option value="">Font</option>
                                    <option value="">Font</option>
                                    <option value="">Font</option>
                                </select>
                            </div>
                            <div class="editor-icons-group">
                                <select name="font weight" width="68px">
                                    <option value="">18</option>
                                    <option value="">20</option>
                                    <option value="">22</option>
                                    <option value="">24</option>
                                </select>
                            </div>
                            <div class="editor-icons-group">
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/image.svg"
                                        title="Add Image">
                                </a>
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/block.svg">
                                </a>
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/align.svg">
                                </a>
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/ohm.svg">
                                </a>
                                <a href="" class="text-decoration-none">
                                    <img src="../../../../../../assets/images/uploads/editor-icons/earth.svg">
                                </a>
                            </div>
                        </div> -->
                        <!-- <textarea class="form-control fs-15" id="exampleFormControlTextarea1" rows="3"
                            [(ngModel)]="objective.desc"></textarea> -->
                    </div>
                </div>

                <div class="col-lg-6 ">

                    <div class="container-fluid">
                        <div class="row">

                            <div class="col-lg-6 ">
                                <p class="fs-15 fw-bold text-info2 m-0 mb-2 text-info1 text-uppercase">Time frame</p>

                                <div class="form-check form-check-inline mb-2">
                                    <input class="form-check-input cursor" type="radio" name="inlineRadiomonth"
                                        id="inlineRadio1" [value]="true" [(ngModel)]="isMonth">
                                    <label class="form-check-label fs-15" for="inlineRadio1">Month</label>
                                </div>
                                <div class="form-check form-check-inline mb-2">
                                    <input class="form-check-input cursor" type="radio" name="inlineRadiomonth"
                                        id="inlineRadio1" [value]="false" [(ngModel)]="isMonth">
                                    <label class="form-check-label fs-15" for="inlineRadio1">Year</label>
                                </div>
                                <div class="form-floating text-dark" style="margin-top: 39px;">
                                    <input type="number" class="form-control no-spin" id="floatingInput" required=""
                                        [(ngModel)]="objective.time" (input)="onInputChange($event)">
                                    <label class="fs-15 pb-0">{{!isMonth? 'Year' : 'Month'}}</label>
                                </div>
                            </div>
                            <div class="col-lg-6 ">
                                <p class="fs-15 fw-bold text-info2  mb-2 text-info1" style="margin-left: 14px;">KPI</p>

                                <div class="form-check form-check-inline mb-2" style="margin-left: 14px;">
                                    <input class="form-check-input cursor" type="radio" name="kpi1" id="inlineRadio2"
                                        [value]="true" [(ngModel)]="isKPI" group="YesNo">
                                    <label class="form-check-label fs-15" for="inlineRadio1">Yes</label>
                                </div>
                                <div class="form-check form-check-inline mb-2" style="margin-left: 10px;">
                                    <input class="form-check-input cursor" group="YesNo" type="radio" name="kpi1"
                                        id="inlineRadio2" [value]="false" [(ngModel)]="isKPI">
                                    <label class="form-check-label fs-15" for="inlineRadio1">No</label>
                                </div>
                                <div class="form-floating text-dark mb-34"
                                    style="margin-top: 39px; margin-left:14px ; width: 300px;" *ngIf="isKPI">
                                    <input type="text" class="form-control" id="floatingInput"
                                        placeholder="name@example.com" value="" required="" [(ngModel)]="objective.kpi">

                                </div>
                            </div>

                        </div>
                    </div>







                </div>


            </div>
        </div>
        <hr class="m-0">
        <div class="col pt-0 ps-3 pe-4 pb-3 pt-2 chang-1">
            <div class="d-flex justify-content-between align-items-start">
                <p class="fs-15 fw-500 text-info2 w-75">GOAL MAPPING</p>
                <button class="btn btn-info1 text-light" data-bs-toggle="dropdown">+ Add</button>
                <ul class=" dropdown-menu fs-15 p-0 w-20 " aria-labelledby="dropdownMenuLink"
                    style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                    data-popper-placement="bottom-start">
                    <li class="bg-secondary">
                        <a class="dropdown-item" href="javascript:void(0)">
                            SEARCH GOALS <img src="../../../../../../assets/images/uploads/search black.svg" alt=""
                                class="float-end mt-1"></a>
                    </li>

                    <li *ngFor="let goalObj of goals;index as k">
                        <a class="dropdown-item" href="javascript:void(0)">
                            <input class="form-check-input me-2" type="checkbox" [(ngModel)]="goalObj.checked"
                                id="[{{k}}]" />
                            {{TrimHtmlContent(goalObj.title)}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tags-container">
                <ng-container *ngFor="let goalObj of goals">
                    <span class="tag" *ngIf="goalObj.checked"> {{TrimHtmlContent(goalObj.title)}}<a
                            href="javascript:void(0);">
                            <img src="../../../../../../assets/images/uploads/close-red.svg" height="10" width="10"
                                class="ms-1" (click)="removeSelectedList(goalObj)">
                        </a>
                    </span>
                </ng-container>
            </div>
        </div>
        <hr class="m-0">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-lg-6 col-xl-3 pt-2 pe-2 ms-3 chang-2 border-end  border-2" style="width: 350px;">
                    <div class="d-flex justify-content-between align-items-start mb-4">
                        <p class="fs-15 fw-500 text-info2 w-50">BUSINESS PROJECTS</p>
                        <!-- <button class="btn btn-info1 text-light" (click)="onOpenPopup('businessProjects')"
                        data-bs-toggle="modal" data-bs-target="#addProject">+ Add</button> -->
                        <button class="btn btn-info1 text-light" data-bs-toggle="dropdown">+ Add</button>
                        <ul class=" dropdown-menu fs-15 p-0 w-20 " aria-labelledby="dropdownMenuLink"
                            style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                            data-popper-placement="bottom-start">
                            <li class="bg-secondary">
                                <a class="dropdown-item" href="javascript:void(0)">
                                    PROJECTS</a>
                            </li>
                            <li *ngFor="let bproj of this.popupConfig.businessProjects.list; index as k">
                                <a class="dropdown-item" href="javascript:void(0)">
                                    <input class="form-check-input me-2" type="checkbox" [(ngModel)]="bproj.checked"
                                        id="[{{k}}]" />
                                    {{bproj.prgName}}
                                </a>
                            </li>


                        </ul>
                    </div>
                    <div class="tags-container">
                        <ng-container *ngFor="let bproj of this.popupConfig.businessProjects.list">
                            <span class="tag" *ngIf="bproj.checked"> {{bproj.prgName}}<a href="javascript:void(0);">
                                    <img src="../../../../../../assets/images/uploads/close-red.svg" height="10"
                                        width="10" class="ms-1" (click)="removeSelectedList(bproj)">
                                </a>
                            </span>
                        </ng-container>
                    </div>
                    <!-- <div class="tags-container">
                    <span class="tags" *ngFor="let bproj of selectedobjective.bprogs">{{bproj.prgName}} <a>
                            <img src="../../../../../../assets/images/uploads/close-red.svg" height="10" width="10"
                                class="ms-1">
                        </a>
                    </span>
                </div> -->
                </div>
                <div class="col-lg-6 col-xl-3  pt-2 pe-2 ps-2 border-end chang-2 border-2" style="width: 350px;">
                    <div class="d-flex justify-content-between align-items-start mb-4">
                        <p class="fs-15 fw-500 text-info2 w-50">IT PROJECTS</p>
                        <!-- <button class="btn btn-info1 text-light" (click)="onOpenPopup('itProjects')" data-bs-toggle="modal"
                        data-bs-target="#addProject">+ Add</button> -->
                        <button class="btn btn-info1 text-light" data-bs-toggle="dropdown">+ Add</button>
                        <ul class=" dropdown-menu fs-15 p-0 w-20 " aria-labelledby="dropdownMenuLink"
                            style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                            data-popper-placement="bottom-start">
                            <li class="bg-secondary">
                                <a class="dropdown-item" href="javascript:void(0)">
                                    PROJECTS </a>
                            </li>
                            <li *ngFor="let itproj of  this.popupConfig.itProjects.list; index as i">
                                <a class="dropdown-item" href="javascript:void(0)">
                                    <input class="form-check-input me-2" type="checkbox" [(ngModel)]="itproj.checked"
                                        id="[{{i}}]" />
                                    {{itproj.prgName}}
                                </a>
                            </li>



                        </ul>
                    </div>
                    <div class="tags-container">
                        <ng-container *ngFor="let itproj of this.popupConfig.itProjects.list">
                            <span class="tag" *ngIf="itproj.checked"> {{itproj.prgName}}<a href="javascript:void(0);">
                                    <img src="../../../../../../assets/images/uploads/close-red.svg" height="10"
                                        width="10" class="ms-1" (click)="removeSelectedList(itproj)">
                                </a>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3  pt-2 pe-2 ps-2 border-end chang-2 border-2" style="width: 350px;">
                    <div class="d-flex justify-content-between align-items-start mb-4">
                        <p class="fs-15 fw-500 text-info2 w-50">Applications</p>
                        <button class="btn btn-info1 text-light" data-bs-toggle="dropdown">+ Add</button>
                        <ul class=" dropdown-menu fs-15 p-0 w-20 " aria-labelledby="dropdownMenuLink"
                            style="position: absolute; inset: 0px auto auto 0px; margin: 0px; transform: translate3d(0px, 42px, 0px);"
                            data-popper-placement="bottom-start">
                            <li class="bg-secondary">
                                <a class="dropdown-item">APPLICATIONS</a>
                            </li>
                            <li *ngFor="let a of this.popupConfig.applications.list;index as k">
                                <a class="dropdown-item" href="javascript:void(0)">
                                    <input class="form-check-input me-2" type="checkbox" [(ngModel)]="a.checked"
                                        id="[{{k}}]" />
                                    {{a.appName}}
                                </a>
                            </li>
                        </ul>

                    </div>
                    <div class="tags-container">
                        <ng-container *ngFor="let app of this.popupConfig.applications.list">
                            <span class="tag" *ngIf="app.checked"> {{app.appName}}<a href="javascript:void(0);">
                                    <img src="../../../../../../assets/images/uploads/close-red.svg" height="10"
                                        width="10" class="ms-1" (click)="removeSelectedList(app)">
                                </a>
                            </span>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3  pt-2 pe-4 ps-2 border-end chang-2 border-2" style="width: 350px;">
                    <div class="d-flex justify-content-between align-items-start mb-4">
                        <p class="fs-15 fw-500 text-info2 w-50">SUPPLIERS</p>
                        <!-- <button class="btn btn-info1 text-light" (click)="onOpenPopup('suppliers')" data-bs-toggle="modal"
                        data-bs-target="#addProject">+ Add</button> -->
                        <button class="btn btn-info1 text-light" data-bs-toggle="dropdown">+ Add</button>
                        <ul class=" dropdown-menu fs-15 p-0 w-20 " aria-labelledby="dropdownMenuLink" style="position: absolute; inset: 0px auto auto 0px; margin: 0px; 
                            transform: translate3d(0px, 42px, 0px);" data-popper-placement="bottom-start">
                            <li class="bg-secondary">
                                <a class="dropdown-item"> SUPPLIERS </a>
                            </li>
                            <li *ngFor="let s of this.popupConfig.suppliers.list; index as v">
                                <a class="dropdown-item" href="javascript:void(0)">
                                    <input class="form-check-input me-2" type="checkbox" [(ngModel)]="s.checked"
                                        id="[{{v}}]" />
                                    {{s.supplierName}}
                                </a>
                            </li>


                        </ul>
                    </div>
                    <div class="tags-container">
                        <ng-container *ngFor="let sup of this.popupConfig.suppliers.list">
                            <span class="tag" *ngIf="sup.checked"> {{sup.supplierName}}<a href="javascript:void(0);">
                                    <img src="../../../../../../assets/images/uploads/close-red.svg" height="10"
                                        width="10" class="ms-1" (click)="removeSelectedList(sup)">
                                </a>
                            </span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- </div>
    </div>
</div> -->

    <div class="modal fade" id="addProject" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered ">
            <div class="modal-content">
                <div class="modal-header py-2 bg-info1 text-light">
                    <h1 class="modal-title fs-18" id="staticBackdropLabel">{{currentActivatedPopupItem.title}}</h1>
                    <div>
                        <button type="button" class="btn btn-info2 text-light rounded-circle" data-bs-dismiss="modal">
                            <img src="../../../../../../assets/images/uploads/close-white.svg" height="11px">
                        </button>
                    </div>
                </div>
                <div class="modal-body px-0 py-0">
                    <div class="px-4 d-flex justify-content-between bg-secondary py-2 border-bottom">
                        <div class="d-flex align-items-center">
                            <span class="me-4">{{currentActivatedPopupItem.list.length}}
                                {{currentActivatedPopupItem.blockname}}</span>
                            <div class="d-flex">
                                <!-- <input type="search"
                                    class="form-control me-2 rounded-0 bg-secondary border-0 border-bottom border-dark search-bar-active"
                                    placeholder="Search..">
                                <button class="btn btn-info1 search-btn px-2"><img src="../../../../../../assets/images/uploads/search.svg"
                                        alt=""></button> -->
                            </div>
                        </div>
                        <button class="btn btn-info1 border-0 text-white" (click)="addMappings();"
                            data-bs-dismiss="modal">+
                            ADD</button>
                    </div>

                    <ul class="checkbox-list px-0 m-0 overflow-auto">
                        <li class="px-4 py-2 border-bottom" *ngFor="let popupItem of currentActivatedPopupItem.list">
                            <div class="form-check" for="flexCheckDefault">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="popupItem.checked"
                                    id="flexCheckDefault">
                                <label class="form-check-label w-100"
                                    for="flexCheckDefault">{{getLabel(popupItem,currentActivatedPopupItem.type)}}</label>
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="top-center"></p-toast>