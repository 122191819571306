<div class="modal-body p-0">
  <div class="container-fluid">
    <div class="back d-flex" (click)="backToObjective()" *ngIf="screen === 'businessobjectives'">
      Back to <span [innerHTML]="selectedObjective.title"></span>
    </div>
    <div class="row">
      <!-- <div class="col-3 border-end sidebar-model p-0" style="max-width: 275px;">
                <div class="m-sidebar-header border-bottom d-flex justify-content-between align-items-center">
                    <p class="fs-15 m-0 text-info2">{{supdata.length + ' Suppliers'}}</p>
                    <input type="search" class="bg-secondary1 search-field me-1" placeholder="Search">
                    <div class="search-btn active">
                        <img src="../../assets/images/uploads/search black.svg" alt="" class="float-end m-1" height="15px" width="15px">
                    </div>
                </div>
                <ul class="p-0 fs-15" style="max-height: 700px; overflow-y:auto">
                    <li *ngFor="let item of supdata">
                      <a href="javascript:void" (click)="selectedsupplier(item)" [ngClass]="{'activated' : item.isSelected }" >{{item.supplierName}}</a>
                    </li>
                </ul>
            </div> -->
      <div class="col px-3 py-4">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12 d-flex flex-wrap">
              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/Competency.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">competency</p>
                </div>
                <div
                  class="color-box"
                  [ngStyle]="getBgColor(supdata.competency)"
                ></div>
                <p class="down-content">
                  {{
                    supdata.competency ? supdata.competency : "Not Assessedd"
                  }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/Innovation.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">innovation</p>
                </div>
                <div
                  class="color-box2"
                  [ngStyle]="getBgColor(supdata.innovation)"
                ></div>
                <p class="down-content">
                  {{
                    supdata.innovation ? supdata.innovation : "Not Assessedd"
                  }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/performance.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">performance</p>
                </div>
                <div
                  class="color-box3"
                  [ngStyle]="getBgColor(supdata.performance)"
                ></div>
                <p class="down-content">
                  {{
                    supdata.performance ? supdata.performance : "Not Assessedd"
                  }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/quality.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">quality</p>
                </div>
                <div
                  class="color-box4"
                  [ngStyle]="getBgColor(supdata.quality)"
                ></div>
                <p class="down-content">
                  {{ supdata.quality ? supdata.quality : "Not Assessedd" }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/Efficiency.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">efficiency</p>
                </div>
                <div
                  class="color-box5"
                  [ngStyle]="getBgColor(supdata.delivery_efficiency)"
                ></div>
                <p class="down-content">
                  {{
                    supdata.delivery_efficiency
                      ? supdata.delivery_efficiency
                      : "Not Assessedd"
                  }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/Proactive.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">proactive</p>
                </div>
                <div
                  class="color-box6"
                  [ngStyle]="getBgColor(supdata.proactive)"
                ></div>
                <p class="down-content">
                  {{ supdata.proactive ? supdata.proactive : "Not Assessedd" }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/Mindset.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">duplicate</p>
                </div>
                <div
                  class="color-box7"
                  [ngStyle]="getBgColor(supdata.mindset)"
                ></div>
                <p class="down-content">
                  {{ supdata.mindset ? supdata.mindset : "Not Assessedd" }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/Market_Risk.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">market risk</p>
                </div>
                <div
                  class="color-box8"
                  [ngStyle]="getBgColor(supdata.risk)"
                ></div>
                <p class="down-content">
                  {{ supdata.risk ? supdata.risk : "Not Assessedd" }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/Cost.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">cost</p>
                </div>
                <div
                  class="color-box9"
                  [ngStyle]="getBgColor(supdata.cost)"
                ></div>
                <p class="down-content">
                  {{ supdata.cost ? supdata.cost : "Not Assessedd" }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/Talent.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">talent</p>
                </div>
                <div
                  class="color-box10"
                  [ngStyle]="getBgColor(supdata.talent)"
                ></div>
                <p class="down-content">
                  {{ supdata.talent ? supdata.talent : "Not Assessedd" }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/perception.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">perception</p>
                </div>
                <div
                  class="color-box11"
                  [ngStyle]="getBgColor(supdata.perception)"
                ></div>
                <p class="down-content">
                  {{
                    supdata.perception ? supdata.perception : "Not Assessedd"
                  }}
                </p>
              </div>

              <div class="main-box">
                <div class="img-box">
                  <img
                    src="../../assets/images/uploads/decision.png"
                    alt=""
                    height="27px"
                    width="27px"
                    class="imagee"
                  />
                  <p class="title">decision</p>
                </div>
                <div class="color-box12">
                  <img
                    class="box-img"
                    [src]="getdecisionicon(supdata.decision)"
                  />
                </div>
                <p class="down-content">
                  {{ supdata.decision ? supdata.decision : "Merge" }}
                </p>
              </div>
            </div>

            <div class="col-12">
              <hr />
            </div>

            <div class="col-lg-3">
              <div class="d-flex align-items-center mb-2">
                <img
                  [src]="supplierPic"
                  alt=""
                  class="me-3"
                  width="74px"
                  appImageFallback [fallbackSrc]="'../../../../../../assets/images/public/default-avatar.png'"
                />
                <div>
                  <p class="fs-13 text-info2 mb-0 mt-3 mt-lg-0">UNIQUE ID*</p>
                  <p class="fs-16 m-0 ps-12" [innerHTML]="supdata.conID"></p>
                </div>
              </div>
            </div>

            <div class="col-xl-3">
              <p class="text-info2 mb-0 fs-13">SUPPLIER NAME</p>
              <p class="mb-0 fs-16" [innerHTML]="supdata.supplierName"></p>
            </div>

            <div class="col-6 mb-3">
              <p class="text-info2 mb-1 fs-13">DESCRIPTION</p>
              <p
                class="mb-0 fs-16"
                [innerHTML]="supdata.desc"
                style="max-height: 120px; overflow-y: auto"
              ></p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">CONTRACT ENTRY DATE</p>
              <p class="mb-0 fs-16">
                {{ supdata.conEntryDate | date : company?.display_date_format }}
              </p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">SUPPLIER TIER</p>
              <p class="mb-0" [innerHTML]="supdata.supplierTier"></p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">RELATIONSHIP OWNER</p>
              <p class="mb-0" [innerHTML]="supdata.conOwner"></p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">
                ANNUAL CONTRACT VALUE(<span
                  [innerHTML]="company ? company.curr : ''"
                ></span
                >)
              </p>
              <p class="mb-0" [innerHTML]="supdata.acv | number"></p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">
                TOTAL CONTRACT VALUE(<span
                  [innerHTML]="company ? company.curr : ''"
                ></span
                >)
              </p>
              <p class="mb-0" [innerHTML]="supdata.tcv | number"></p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">SUPPLIER STATUS</p>
              <p class="mb-0" [innerHTML]="supdata.conStatus"></p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">CONTRACT EXIT DATE</p>
              <p class="mb-0">
                {{ supdata.conExitDate | date : company?.display_date_format }}
              </p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">TERMINATION NOTICE</p>
              <p class="mb-0" [innerHTML]="supdata.terminationNotice"></p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">REGION</p>
              <p class="mb-0" [innerHTML]="supdata.region"></p>
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3">
              <p class="text-info2 mb-1 fs-13">COUNTRY</p>
              <p class="mb-0" [innerHTML]="supdata.country"></p>
            </div>

            <div class="col-12">
              <hr class="my-2 mt-3 px-3" />
            </div>

            <div class="col-6 col-lg-3 mb-3 mt-3 d-flex">
              <div *ngFor="let cat of supdata?.categories">
                <p class="text-info2 mb-1 fs-13 supp-label" *ngIf="cat.value">
                  {{ cat.label }}
                </p>
                <p class="mb-0">{{ cat.value }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
