import { Component, ElementRef, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ITechAlignment } from 'src/app/models/input/techstrategy/themeAlignemnt';
import { IProgram, ISwotBS, ITechThemes } from 'src/app/models/shared/loopBack';
import { AppService } from 'src/app/providers/appservice/app.service';
import { BusinessStrategyService } from 'src/app/providers/input/business-strategy.service';
import { ManagedataService } from 'src/app/providers/input/managedata/managedata.service';
import { TechstrategyService } from 'src/app/providers/input/techstrategy.service';
import Utils from 'src/app/utils/utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonService } from 'src/app/helpers/common.service';
import { PortfolioThemePopupComponent } from '../portfolio-theme-popup/portfolio-theme-popup.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ObjsharedPopupComponent } from '../objshared-popup/objshared-popup.component';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { SharedService } from 'src/app/modules/home/data.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-theme-align',
  templateUrl: './theme-align.component.html',
  styleUrls: ['./theme-align.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ThemeAlignComponent implements OnInit {

  techThemes: IProgram[];
  criticalPrograms: IProgram[];
  businessPrograms: IProgram[];
  businessObjectives: IProgram[];
  oprograms: IProgram[] = [];
  osprograms: any[] = [];
  swotBS: ISwotBS;
  Projects: any[] = [];
  selectedProjects: any[] = [];
  clonedSelectedProjects: any[] = [];
  rowValue: string = "Technology Themes";
  selectedColumn: string = "Business Objectives";
  statusData: any = [{ status: 0, statusName: 'Non-Essential' },
  { status: 1, statusName: 'Primary Contributor' },
  { status: 2, statusName: 'Secondary Contributor' }];
  filterText: string = 'PROJECTS';
  columnSelector: any = ['Business Projects', 'Business Objectives', 'Technology Themes'];
  rowSelectors: any = ['All Projects', 'IT Projects', 'Business Projects', 'Business Objectives', 'Technology Themes'];
  selectedObjective: any;
  allapp: any[];
  allgoal: any[];
  allsup: any[];
  objectives: any[] = [];
  projectData: any;
  ownerData: any[] = [];
  company: any;
  selectedTypeTitle: string = "Themes";
  thech: any;
  constructor(private techStrategyService: TechstrategyService, private busStartegyService: BusinessStrategyService,
    public manageDataService: ManagedataService, public appService: AppService, private sanitizer: DomSanitizer,
    private el: ElementRef, private renderer: Renderer2,
    private commonService: CommonService,
    private dashboardService: DashboardService,
    private dialogService: DialogService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.commonService.getCompany(this.appService.currentActivatedFeature.strategyId).subscribe(x => {
      this.company = x;
    });
    forkJoin([
      this.manageDataService
        .getProjectData(this.appService.currentActivatedFeature.strategyId, false),
      this.techStrategyService.getTechnologyThemes(),
      this.busStartegyService.getBusinessObjectives()
    ]).subscribe((responses) => {
      this.processProjectData(responses[0] || []);
      this.processBusinessObjectives(responses[2] || []);
      this.processTechThemes(responses[1] || []);
      this.loadRows("Technology Themes");
      this.onColumnSelector("Business Objectives");
    });
  }

  processTechThemes(data: any) {
    this.techThemes = [];
    this.thech = data;
    if (this.thech && this.thech.length > 0) {
      this.thech.forEach((x: any) => {
        if (x.themes)
          x.themes.forEach((y: any) => {
            let pr = y.progs && y.progs.length > 0 ? y.progs.map((x: any) => x._id) : [];
            let obj_depend = y.objectives && y.objectives.length > 0 ? y.objectives.map((x: any) => x._id) : [];
            this.techThemes.push({ _id: y._id, prgName: y.name, projects_depend: pr, objectives_depend: obj_depend, checked: false, filtered: false })
          });
      });
    }
  }

  filteredobj: any;
  mainObjdata: any;
  processBusinessObjectives(data: any) {
    this.mainObjdata = data;
    // this.businessObjectives = [];
    this.mainObjdata.categories.forEach((x: any) => {
      const obj = x.objectives;
      // this.businessObjectives = this.businessObjectives.concat(x.objectives);
      obj.map((ob: any) => {
        if (ob.sponsor) {
          this.dashboardService.getSelectedImage(ob.sponsor.pic)
            .subscribe((signedUrl: any) => ob.sponsor.pic = signedUrl)
        }
        let prg = ob.progs.map((p: any) => { return p._id });
        prg.map((p: any) => {
          let o = this.Projects.findIndex((pf: any) => pf._id == p);
          if (!this.Projects[o].objectives) {
            this.Projects[o].objectives = [];
          }
          this.Projects[o].objectives.push(ob);
        })
      });
    });
    this.getBusinessObjectives();
  }
  masterObjectiveData: any
  getBusinessObjectives() {

    this.swotBS = this.mainObjdata;
    if (this.swotBS && this.swotBS.categories && this.swotBS.categories.length > 0) {

      this.businessObjectives = [];
      this.swotBS.categories.forEach((x: any) => {
        this.masterObjectiveData = x.objectives;
        this.masterObjectiveData = this.masterObjectiveData?.filter((x: any) => x?.title);
        this.masterObjectiveData = this.getObjectiveProperties(this.masterObjectiveData);
        x.objectives.forEach((y: any) => {
          this.objectives.push(y);
          let pr = y.progs && y.progs.length > 0 ? y.progs.map((x: any) => x._id) : [];
          this.businessObjectives.push({ _id: y._id, prgName: y.title, projects_depend: pr, checked: false, filtered: false })

        });
      });
    }
    else
      this.businessObjectives = [];
  }

  getObjectiveProperties(masterObjectiveData: any) {
    masterObjectiveData.map((obj: any) => {
      if (obj.sponsor) {
        this.dashboardService.getSelectedImage(obj.sponsor.pic)
          .subscribe((signedUrl: any) => obj.sponsor.pic = signedUrl)
      }
      let prg = obj.progs.map((p: any) => { return p._id });
      prg.forEach((p: any) => {
        let o = this.Projects.findIndex((pf: any) => pf._id == p);
        if (!this.Projects[o]?.objectives) {
          this.Projects[o].objectives = [];
        }
        //Risk icon logic
        if (p.itbApproved == 'rejected') {
          obj['isRisk'] = true;
        }
        //Realisation date logic
        p.benefits_realisations?.forEach((benefit: any) => {
          realisationDts.push(benefit.benefit_delivery_date);
          console.log(JSON.stringify(realisationDts) + 'date')
        });
        this.Projects[o].objectives.push(obj);
      })
      let realisationDts: any[] = [];
      if (realisationDts && realisationDts.length > 0) {
        const latestDate: Date | null = this.getLatestDate(realisationDts);
        obj['realisationDate'] = latestDate;
      }

    });
    return masterObjectiveData;
  }

  getLatestDate(dates: any): Date | null {
    if (dates.length === 0) {
      return null; // Return null if the array is empty
    }
    let maxDate: Date = dates[0];

    for (let i = 1; i < dates.length; i++) {
      if (dates[i] > maxDate) {
        maxDate = dates[i];
      }
    }

    return maxDate;

  }

  onColumnSelector(col: string) {
    if (col === this.rowValue) return;
    this.selectedColumn = col;
    this.selectedProjects = this.clonedSelectedProjects;
    if (col == "Business Objectives") {
      this.filterText = 'OBJECTIVES';
      this.criticalPrograms = this.businessObjectives;
      this.oprograms = JSON.parse(JSON.stringify(this.criticalPrograms));
    }
    else if (col == "Technology Themes") {
      this.filterText = 'THEMES';
      this.criticalPrograms = this.techThemes;
      this.oprograms = JSON.parse(JSON.stringify(this.techThemes));
    }
    else {
      this.filterText = 'PROJECTS';
      this.criticalPrograms = this.businessPrograms;
      this.oprograms = JSON.parse(JSON.stringify(this.criticalPrograms));
    }
  }

  Trimhtmltags(txt: any) {
    //this.charLength=0;
    txt = txt.replaceAll('/', '');
    txt = txt.replaceAll(' style="color: rgb(0, 0, 0);" ', '')
    txt = txt.replaceAll(/<p>|<strong >|<em>|<u>|<span style="color:rgb(0, 0, 0);">|<span>/gi, function (matched: any) {
      return '';
    });
    return txt;
  }

  processProjectData(data: any) {
    this.Projects = data;
    this.osprograms = data;
    this.businessPrograms = [];
    data.filter((x: any) => x.prgType == "business").forEach((y: any) => {
      this.businessPrograms.push({ _id: y._id, prgName: y.prgName, projects_depend: [y._id], checked: false, filtered: false })
    });
  }

  loadRows(rowSelector: any) {
    if (rowSelector === this.selectedColumn) return;
    if (rowSelector === 'IT Projects') {
      this.rowValue = "IT Projects";
      this.selectedTypeTitle = "Projects";
      this.selectedProjects = this.Projects.filter(x => x.prgType == 'it');
      this.osprograms = this.Projects.filter(x => x.prgType == 'it');
      this.clonedSelectedProjects = this.Projects.filter(x => x.prgType == "it");
    }
    else if (rowSelector == 'Business Projects') {
      this.rowValue = "Business Projects";
      this.selectedTypeTitle = "Projects";
      this.selectedProjects = this.Projects.filter(x => x.prgType == "business");
      this.clonedSelectedProjects = this.Projects.filter(x => x.prgType == "business");

      this.osprograms = this.Projects.filter(x => x.prgType == 'it');
    }
    else if (rowSelector == 'Business Objectives') {
      this.rowValue = "Business Objectives";
      this.selectedTypeTitle = "Objectives";
      // Used the name as projects to sync the existing variable
      this.selectedProjects = this.businessObjectives;
      this.clonedSelectedProjects = this.businessObjectives;
    } else if (rowSelector === 'Technology Themes') {
      this.rowValue = "Technology Themes";
      this.selectedTypeTitle = "Themes";
      this.selectedProjects = this.techThemes;
      this.clonedSelectedProjects = this.techThemes;
    } else {
      this.rowValue = "All Projects";
      this.selectedTypeTitle = "Projects";
      this.selectedProjects = this.Projects;
      this.osprograms = this.Projects;
      this.clonedSelectedProjects = this.Projects;
    }
  }

  getStatusValue(tech: any, program: IProgram) {
    if (this.rowValue === 'Technology Themes' && this.selectedColumn === 'Business Objectives') {
      return tech.objectives_depend.findIndex((x: IProgram) => x == program._id) == -1 ? "No Dependency" : "Dependency Exists";
    } else if (this.rowValue === 'Business Objectives' && this.selectedColumn === 'Technology Themes') {
      return program.objectives_depend.findIndex((x: IProgram) => x == tech._id) == -1 ? "No Dependency" : "Dependency Exists";
    } else if ((this.rowValue === 'Technology Themes' || this.rowValue === 'Business Objectives') && this.selectedColumn === 'Business Projects') {
      return tech.projects_depend.findIndex((x: IProgram) => x == program._id) == -1 ? "No Dependency" : "Dependency Exists";
    }

    if (this.selectedColumn == "Business Objectives" || this.selectedColumn == "Technology Themes") {
      return program.projects_depend.findIndex((x: IProgram) => x == tech._id) == -1 ? "No Dependency" : "Dependency Exists";
    }
    else {
      // Else is for business projects
      return tech.projects_dependent_on?.findIndex((x: IProgram) => x._id == program._id) != -1 ||
        tech.projects_depending_on?.findIndex((x: IProgram) => x._id == program._id) != -1 ? "Dependency Exists" : "No Dependency";
    }
  }

  filterRow(project: any) {
    let index = this.selectedProjects.findIndex(x => x._id == project._id);
    if (!this.selectedProjects[index].filtered)
      this.selectedProjects[index].filtered = true;
    else {
      this.selectedProjects[index].filtered = false;
    }
    this.onColumnSelector(this.selectedColumn);
    let selectedRows = this.selectedProjects
      .filter(x => x.filtered);

    if (selectedRows.length > 0) {
      let filterCols: any[] = [];
      const columns = this.criticalPrograms;
      selectedRows.forEach((row) => {
        if ((this.rowValue === 'Technology Themes' && this.selectedColumn === 'Business Objectives')) {
          columns.forEach((col: any) => {
            if (row.objectives_depend.indexOf(col._id) !== -1) {
              filterCols.push(col);
            }
          });
        } else if (this.rowValue === 'Business Objectives' && this.selectedColumn === 'Technology Themes') {
          columns.forEach((col: any) => {
            if (col.objectives_depend.indexOf(row._id) !== -1) {
              filterCols.push(col);
            }
          });
        } else if ((this.rowValue === 'Technology Themes' && this.selectedColumn === 'Business Projects') || this.rowValue === 'Business Objectives' && this.selectedColumn === 'Business Projects') {
          columns.forEach((col: any) => {
            if (col.projects_depend.indexOf(row._id) !== -1) {
              filterCols.push(col);
            }
          });
        } else if (this.selectedColumn === 'Business Objectives' || this.selectedColumn === 'Technology Themes') {
          columns.forEach((col: any) => {
            if (col.projects_depend.indexOf(row._id) !== -1) {
              filterCols.push(col);
            }
          });
        }
        else {
          let ids: any[] = [];
          project.projects_dependent_on.map((x: any) => ids.push(x._id));
          project.projects_depending_on.map((x: any) => ids.push(x._id));
          this.criticalPrograms.filter((x: IProgram) => ids.includes(x._id)).forEach(y => {
            if (!filterCols.some(f => f._id == y._id))
              filterCols.push(y);
          })
        }
      });
      this.criticalPrograms = filterCols;
    }
  }


  filterColumn(program: any) {
    if (this.rowValue?.toLowerCase() != "all projects") {
    }
    else {
      this.selectedProjects = this.Projects.filter(x => x._id == x._id);
    }

    let index = this.criticalPrograms.findIndex(x => x._id == program._id);
    if (!this.criticalPrograms[index].filtered == true)
      this.criticalPrograms[index].filtered = true;
    else
      this.criticalPrograms[index].filtered = false;
    // filtering logic
    let filteredColumns = this.criticalPrograms.filter(x => x.filtered == true);
    if (filteredColumns.length > 0) {
      let filrows: any[] = [];
      filteredColumns.forEach((column, i) => {
        const rows = this.selectedProjects;
        if ((this.rowValue === 'Technology Themes' && this.selectedColumn === 'Business Objectives')) {
          rows.forEach((row: any) => {
            if (row.objectives_depend.indexOf(column._id) !== -1) {
              filrows.push(row);
            }
          });
        } else if (this.rowValue === 'Business Objectives' && this.selectedColumn === 'Technology Themes') {
          rows.forEach((row: any) => {
            if (column.objectives_depend.indexOf(row._id) !== -1) {
              filrows.push(row);
            }
          });
        } else if ((this.rowValue === 'Technology Themes' && this.selectedColumn === 'Business Projects') || this.rowValue === 'Business Objectives' && this.selectedColumn === 'Business Projects') {
          rows.forEach((row: any) => {
            if (row.projects_depend.indexOf(column._id) !== -1) {
              filrows.push(row);
            }
          });
        } else if (this.selectedColumn === 'Business Objectives' || this.selectedColumn === 'Technology Themes') {
          rows.forEach((row: any) => {
            if (column.projects_depend.indexOf(row._id) !== -1) {
              filrows.push(row);
            }
          });
        }
        else {
          let filterprg: any[] = [];
          rows.forEach((x: any) => {
            let ids: any[] = [];
            x.projects_dependent_on.map((y: any) => ids.push(y._id));
            x.projects_depending_on.map((y: any) => ids.push(y._id));
            if (ids.includes(column._id))
              filterprg.push(x);
          });
          filterprg.forEach(y => {
            if (!filrows.some(f => f._id == y._id))
              filrows.push(y);
          })
        }
        if (i == filteredColumns.length - 1)
          this.selectedProjects = filrows;
      });
    } else {
      this.selectedProjects = this.clonedSelectedProjects;
    }
  }

  TrimHtmlTags(txt: any) {
    return Utils.Trimhtmltags(txt);
  }

  truncateText1(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  }

  openObjPopup(o: any) {
    this.selectedObjective = this.objectives.filter(x => x._id == o._id)[0];

    this.dialogService.open(ObjsharedPopupComponent, {
      header: this.truncateText1(this.Trimhtmltags(this.selectedObjective.title), 140),
      width: '1405px',
      height: '800px',
      data: { businessObjectives: this.selectedObjective }
    });

    let appMap = new Map<any, any>(); // Using Map to store unique apps by ID
    let suppMap = new Map<any, any>(); // Using Map to store unique suppliers by ID
    let goalMap = new Map<any, any>(); // Using Map to store unique goals by ID

    // this.categories?.forEach((obj: any) => {
    this.businessObjectives.forEach((app: any) => {
      app.apps.forEach((a: any) => {
        let appdata = this.selectedObjective.apps.find((appId: any) => appId === a._id);
        if (appdata) {
          appMap.set(a._id, a); // Use app ID as the key in the Map
        }
      });

      app.suppliers.forEach((s: any) => {
        let suppdata = this.selectedObjective.suppliers.find((suppId: any) => suppId === s._id);
        if (suppdata) {
          suppMap.set(s._id, s); // Use supplier ID as the key in the Map
        }
      });

      app.goals.forEach((g: any) => {
        let goaldata = this.selectedObjective.goals.find((goalId: any) => goalId === g._id);
        if (goaldata) {
          goalMap.set(g._id, g); // Use goal ID as the key in the Map
        }
      });
    });
    // });

    // Convert Map values to Arrays for allapp, allsup, and allgoal
    this.allapp = Array.from(appMap.values());
    this.allsup = Array.from(suppMap.values());
    this.allgoal = Array.from(goalMap.values());
  }

  projectInfo(project: any) {
    //this.getBusinessObjectives();
    this.projectData = this.Projects.filter((x: any) => x._id == project._id)[0];
    this.dialogService.open(PortfolioThemePopupComponent, {
      header: 'PROJECT INFORMATION',
      width: '1405px',  // Adjust the width here (e.g., '500px', '50%')
      height: '800px',  // Adjust the height here (e.g., '300px', '50%')
      data: { projectData: this.projectData, businessObjectives: this.businessObjectives, screen: 'themes-alignment-shared' }
    });
  }

  FilterRows() {
    this.criticalPrograms = this.oprograms.filter(x => x.checked == true);
    if (this.criticalPrograms.length == 0) {
      this.criticalPrograms = JSON.parse(JSON.stringify(this.oprograms));
    }
  }

  sanitizeHtml(html: string = ''): SafeHtml {
    if (html != undefined) {
      return this.sanitizer.bypassSecurityTrustScript(html);
    } else {
      return '';
    }
  }

  FilterProjects() {
    const selectedFilteredProjects = this.clonedSelectedProjects.filter(x => x.checked);

    if (selectedFilteredProjects?.length > 0) {
      this.selectedProjects = JSON.parse(JSON.stringify(selectedFilteredProjects));
    }
    else {
      this.selectedProjects = JSON.parse(JSON.stringify(this.clonedSelectedProjects));
    }
  }
  openColumnInfoPopup(column: string, data: any) {
    if (column === 'Business Objectives') {
      this.openObjPopup(data);
    } else if (column === 'Business Projects') {
      this.projectInfo(data);
    } else if (column === 'Technology Themes') {
    }
  }
  openRowInfoPopup(data: any) {
    const rowValue = this.rowValue.toLowerCase();
    if (rowValue === 'business projects' || rowValue === 'it projects' || rowValue == 'all projects') {
      this.projectInfo(data);
    } else if (rowValue === 'business objectives') {
      this.openObjPopup(data);
    } else if (rowValue === 'technology themes') {
    }
  }
}
