<div class="modal-body p-0">
  <div class="p-3 px-4 bg-light1" *ngIf="!businessObjectives.hideTop">
    <p class="fs-15 text-info2 m-0">KPI</p>
    <p class="fs-15 m-0">{{businessObjectives?.kpi}}</p>
  </div>

  <div class="p-2 fs-15 mb-1" *ngIf="!businessObjectives.hideTop">
    <p class="text-info2 mb-1">DESCRIPTION</p>
    <p class="mb-0" [innerHTML]="businessObjectives.desc" style="height: 100px; max-height: 100px; overflow-y:auto"></p>

  </div>

  <div class="container-fluid">
    <div class="row px-3">
      <div *ngIf="!businessObjectives.hideTop">
        <div class="d-flex">
          <div class="col-lg-4">
            <p class="fs-15 text-info2 mb-1 mt-3 mt-lg-0">SPONSOR</p>
            <div class="d-flex align-items-center mb-2">
              <img [src]="businessObjectives?.pics || '../../../../../../assets/images/public/default-avatar.png'" alt=""
                class="me-3" width="50px" height="50px" appImageFallback
                [fallbackSrc]="'../../../../../../assets/images/public/default-avatar.png'">
              <p class="fs-15 m-0 ps-12" [innerHTML]="businessObjectives?.sponsor?.empName"></p>
              <!-- <p class="fs-15 m-0 ps-12" [innerHTML]="businessObjectives?.OwnerName"></p> -->
            </div>
          </div>
  
          <div class="col-lg-4">
            <p class="fs-15 text-info2 mb-1 mt-3 mt-lg-0">OBJECTIVE REALISATION DATE</p>
            <div class="d-flex flex-wrap align-items-center mb-2">
              <img src="../../../../../../../assets/images/uploads/calendar.svg" alt="" class=" py-2 me-3">
              <p class="fs-15 m-0 ps-12">{{businessObjectives?.realisationDate | date: company?.display_date_format }}</p>
            </div>
          </div>
  
          <div class="col-lg-4">
            <p class="fs-15 text-info2 mb-1 mt-3 mt-lg-0">TIME FRAME:
              <span class="fs-15 m-0 ps-12" style="color:black"
                *ngIf="businessObjectives?.time && businessObjectives?.timeUnit === 'year'">
                {{ businessObjectives.time }} ({{ businessObjectives?.timeUnit === 'year' ? businessObjectives?.timeUnit :
                ''}})
              </span>
              <span class="fs-15 m-0 ps-12" style="color:black"
                *ngIf="businessObjectives?.time && businessObjectives?.timeUnit === 'month'">
                {{ businessObjectives.time }} ({{ businessObjectives.timeUnit === 'month' && businessObjectives?.time ===
                1 ? businessObjectives?.timeUnit : businessObjectives?.timeUnit + '(s)' }})
              </span>
            </p>
          </div>
        </div>

      <div class="col-lg-12 mt-2" style="max-height: 130px;
            overflow-y: auto; height: 130px">
          <p class="text-info2 mb-2 mb-xl-3 fs-15 mt-3  mt-xl-0">GOALS ({{businessObjectives?.goals.length}})</p>
          <div class="tags" *ngFor="let obgoals of businessObjectives?.goals">
            <span class="goal-obj" [innerHTML]="Trimhtmltags(obgoals?.title)"></span>
          </div>
      </div>

        <h2 class="tech-component"><span>TECHNOLOGY COMPONENT</span></h2>
      </div>

      <div class="row">
        <div class="col-lg-4 fs-15 border-end tech-component-col">
          <p class="text-info2 mb-1 mb-xl-2 fs-15 mt-1  mt-xl-0">PROJECTS ({{businessObjectives.progs.length}})</p>

          <div class="d-flex justify-content-between align-items-center py-2 border-bottom"
            *ngFor="let obprogs of businessObjectives?.progs">
            <p class="m-0 cursor-pointer" [innerHTML]="obprogs?.prgName" (click)="openProject(obprogs._id)"></p>
            <div class="" *ngIf="obprogs?.isRisk">
              <img src="../../../../../../../assets/images/uploads/alert.svg" alt="" class="me-1"> <span>Project
                at Risk</span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 fs-15 border-end tech-component-col">
          <div class="d-flex justify-content-between align-items-center py-2">
            <p class="text-info2 mb-1 mb-xl-2 fs-15 mt-1  mt-xl-0">APPLICATIONS ({{businessObjectives.apps.length}})</p>
            <div class="fa-ta-switch">
              <span class="title"> FA </span>
              <span class="title"> TA </span>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center py-2 border-bottom"
            *ngFor="let obapp of businessObjectives?.apps">
            <p class="m-0 cursor-pointer" [innerHTML]="obapp?.appName" (click)="openApplication(obapp._id)"></p>
            <div class="fa-ta-switch">
              <span [style.background-color]="getFATAColor(obapp.faQusAvg)" class="switch1">
                {{obapp.faQusAvg}}</span>
              <span [style.background-color]="getFATAColor(obapp.taQusAvg)" class="switch1">
                {{obapp.taQusAvg}}</span>
            </div>
          </div>

        </div>

        <div class="col-lg-4 fs-15  tech-component-col">
          <p class="text-info2 mb-1 mb-xl-2 fs-15 mt-1  mt-xl-0">SUPPLIERS ({{businessObjectives.suppliers.length}})</p>

          <div class="d-flex justify-content-between align-items-center py-2 border-bottom"
            *ngFor="let obsupp of businessObjectives?.suppliers">
            <p class="m-0 cursor-pointer" [innerHTML]="obsupp?.supplierName" (click)="openSupplier(obsupp._id)"></p>
            <div class="supplier-risk" *ngIf="obsupp?.isRisk">
              <img src="../../../../../../../assets/images/uploads/alert.svg" alt="" class="me-1">
              <span> Supplier at Risk</span>
              <!-- <img src="../../../../../../../assets/images/uploads/i.svg" alt="" class="me-1" data-toggle="tooltip" data-placement="top"
                  title="{{obsupp.riskTooltip}}">  -->
            </div>
          </div>
        </div>
      </div>



    </div>
  </div>

</div>