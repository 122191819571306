import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { DashboardService } from "../providers/home/dashboard.service";
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    result = new Subject<any>();
    showPasswordStrengthIndicator = false;

    constructor(private dashBoardService: DashboardService, private messageService: MessageService, private sanitizer: DomSanitizer) {

    }
    setCompany(val: any) {
        val.display_date_format = val?.date_format?.replace("mm", "MM");
        val.date_format = val?.date_format?.replace("yyyy", "yy");
        localStorage.setItem('Company', JSON.stringify(val));
    }

    getCompany(id?: any) {
        if (localStorage.getItem('Company') != "undefined" && localStorage.getItem('Company') != null) {
            setTimeout(() => {
                this.result.next(JSON.parse(localStorage.getItem('Company') ?? ''));
            }, 100);
            return this.result.asObservable();
        } else {
            this.dashBoardService.getCompaniesDetails().subscribe(res => {
                if (res) {
                    let company = res.filter((x: any) => x.company && x.company && x.company.strategy && x.company.strategy._id == id);
                    if (company.length > 0) {
                        this.setCompany(company[0].company);
                        this.result.next(company[0].company);
                    }
                }
            })
            return this.result.asObservable();
        }

    }

    deleteCompany() {
        localStorage.removeItem('Company');
    }

    getAllCompanies() {
        this.dashBoardService.getCompaniesDetails().subscribe((respData) => {
            this.result.next(respData);
        });
        return this.result.asObservable();
    }

    displayPasswordStrengthIndicator() {
        this.showPasswordStrengthIndicator = true;
    }

    hidePasswordStrengthIndicator() {
        this.showPasswordStrengthIndicator = false;
    }

    clearBrowserStorages() {
        localStorage.clear();
        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    }

    isAdmin() {
        return this.getCurrentUser()?.roles?.indexOf('adm') !== -1 || false
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('currentUser') || '{}');
    }

    copyToClipboard(text: any): void {
        navigator.clipboard.writeText(text).catch(() => {
            console.error("Unable to copy text");
        });
    }
    encryptData(data: string): string {
        const encryptedData = CryptoJS.AES.encrypt(data, environment.CRYPTO_SECRET).toString();
        return encryptedData;
    }

    capitalizeWord = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    convertDate = (isoDateString: string, format = "MM/DD/YYYY") => {
        const date = new Date(isoDateString); // Convert ISO string to Date object
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Month (0-11) + 1
        const dd = String(date.getDate()).padStart(2, '0');      // Day of the month
        const yyyy = date.getFullYear();                        // Full year
        // Return date based on the format
        if (format === "MM/DD/YYYY".toLocaleLowerCase()) {
            return `${mm}/${dd}/${yyyy}`;
        } else if (format === "DD/MM/YYYY".toLocaleLowerCase()) {
            return `${dd}/${mm}/${yyyy}`;
        } else {
            throw new Error("Invalid format. Use 'MM/DD/YYYY' or 'DD/MM/YYYY'.");
        }
    }
}