import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/helpers/common.service';
import { DashboardService } from 'src/app/providers/home/dashboard.service';
import { BusinessStrategyService } from 'src/app/providers/input/business-strategy.service';
import { SafeUrl } from '@angular/platform-browser';
// Import the Quill library
import Quill from 'quill';
// Import the Quill Image Resize Module for p-editor
import ImageResize from 'quill-image-resize-module';

// Register the Image Resize Module
Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: 'app-visiongoals',
  templateUrl: './visiongoals.component.html',
  styleUrls: ['./visiongoals.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VisiongoalsComponent implements OnInit {
  editorModules = {
    imageResize: {},
  };
  ownerData: any[] = [];
  isAdd: any = false;
  goalPic: SafeUrl;
  ownerPic: SafeUrl;
  goalObj: any = {
    color: '',
    title: '',
    pic: '',
    signedPic: '',
    desc: '',
    spi: '',
    owner: '',
    _id: undefined,
  };
  headerText: string;
  constructor(
    private busStartegyService: BusinessStrategyService,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private messageService: MessageService,
    private dashboardService: DashboardService,
    private commonService: CommonService,
    private dialogref: DynamicDialogRef,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.ownerData = this.config?.data?.ownerData;
    if (this.config?.data?.isEdit) {
      // this.ownerData = this.config?.data?.goalOwners;
      this.goalObj.color = this.config?.data?.goal?.color;
      this.goalObj.title = this.config?.data?.goal?.title;
      this.goalObj.desc = this.config?.data?.goal?.desc;
      this.goalObj.spi = this.config?.data?.goal?.spi;
      this.goalObj.owner = this.config?.data?.goal?.owner;
      this.goalObj._id = this.config?.data?.goal?._id;
      this.getOwnerpic(this.config?.data?.goal?.owner);
      this.dashboardService
        .getSelectedImage(this.config?.data?.goal?.pic)
        .subscribe((url) => {
          this.goalPic = url;
        });
      this.goalObj.pic = this.config?.data?.goal?.pic;
      this.headerText = 'Edit Goal';
    } else {
      this.isAdd = true;
      if (this.config?.data?.goal) {
        this.goalObj = JSON.parse(JSON.stringify(this.config?.data?.goal));
      }
      if (this.config?.data?.goal?.owner) {
        this.getOwnerpic(this.config?.data?.goal?.owner);
      }
      this.dashboardService
        .getSelectedImage(this.config?.data?.goal?.pic)
        .subscribe((url) => {
          this.goalPic = url;
        });
      this.headerText = 'New Goal';
    }
  }

  saveGoal() {
    this.busStartegyService.SaveGoal(this.goalObj).subscribe((_res) => {
      this.ref.close({ goalObj: this.goalObj, isSaved: true });
    });
  }

  onClose() {
    this.dialogref.close();
  }

  getOwnerpic(id: any) {
    const ownerObj = this.ownerData.find((x) => x._id == id);
    if (ownerObj) {
      // this.ownerPic = ownerObj.pic;
      this.dashboardService
        .getSelectedImage(ownerObj.pic)
        .subscribe((url) => (this.ownerPic = url));
    }
  }

  onOwnerChange() {
    const ownerObj = this.ownerData.find((x) => x._id == this.goalObj.owner);
    // this.objective.sponsor = {...ownerObj};
    // this.ownerPic = ownerObj?.pic;
    this.dashboardService
      .getSelectedImage(ownerObj.pic)
      .subscribe((url) => (this.ownerPic = url));
  }

  openImagePopup() {
    this.dialogref.close({ goalObj: this.goalObj, isSaved: false });
  }
  ngOnDestroy() {
    this.goalPic = '';
  }
  // getSelectedImage(url: string) {
  //   this.dashboardService.getSelectedImage(avatar)
  //   .subscribe(url => this.avatar = avatar)
  // }
}
